import './date'; // extends dayjs with plugins
import { localDateFormat } from '@/common/utils/date';
import dayjs, { Dayjs } from 'dayjs';
import {
  formatCurrency as _formatCurrency,
  formatLbs as _formatLbs,
  formatNumber as _formatNumber,
  formatPercentDoubleDigitPrecision as _formatPercentDoubleDigitPrecision,
  Lbs,
} from '@/common/utils/format';

// 10%, 10.1%
export const formatPercentSingleDigitPrecision = (x: number) =>
  x == null || isNaN(x) ? '' : `${Number((x * 100).toFixed(1))}%`;

export const formatPercentDoubleDigitPrecision = _formatPercentDoubleDigitPrecision;

export const formatPercentDry = formatPercentDoubleDigitPrecision;
export const formatAsFedPercent = formatPercentDoubleDigitPrecision;

export const formatDateForDB = (date?: Dayjs | Date | string | null) =>
  date == null ? '' : dayjs(date).format(localDateFormat);

export const formatNumber = _formatNumber;

export const formatCurrency = _formatCurrency;

export const formatLbs = _formatLbs;

export const formatProjectedWeightLbs = (num?: Lbs, suffix: boolean = true, prefix: boolean = false) =>
  // Estimated weight is not an exact science. Don't show decimal places.
  formatLbs(num, suffix, prefix, { maximumFractionDigits: 0 });

type Fahrenheit = number | string | null;
export const formatFahrenheits = (
  num?: Fahrenheit,
  suffix: boolean = true,
  prefix: boolean = false,
  options?: Intl.NumberFormatOptions
) => {
  const _suffix = suffix ? ' °F' : '';
  const _prefix = prefix && Number(num) > 0 ? '+' : '';
  return num == null
    ? ''
    : `${_prefix}${num.toLocaleString(undefined, { maximumFractionDigits: 2, ...options })}${_suffix}`;
};

type Tons = number | string | null;
type Bushels = number | string | null;
export const formatTons = (num?: Tons, suffix: boolean = true, prefix: boolean = false) => {
  const _suffix = suffix ? ' tons' : '';
  const _prefix = prefix && Number(num) > 0 ? '+' : '';
  return num == null ? '' : `${_prefix}${num.toLocaleString(undefined, { maximumFractionDigits: 2 })}${_suffix}`;
};

export const formatBushels = (num?: Bushels, suffix: boolean = true, prefix: boolean = false) => {
  const _suffix = suffix ? ' bushels' : '';
  const _prefix = prefix && Number(num) > 0 ? '+' : '';
  return num == null ? '' : `${_prefix}${num.toLocaleString(undefined, { maximumFractionDigits: 2 })}${_suffix}`;
};

export const formatDays = (num?: number | string | null, suffix: boolean = true) => {
  const _suffix = suffix ? ' days' : '';
  return num == null ? '' : `${num.toLocaleString(undefined, { maximumFractionDigits: 2 })}${_suffix}`;
};

export const formatMcalPerCwt = (num?: number | string | null, suffix: boolean = true) => {
  const _suffix = suffix ? ' Mcal/cwt' : '';
  return num == null ? '' : `${num.toLocaleString(undefined, { maximumFractionDigits: 2 })}${_suffix}`;
};

/**
 * Format a date for display in the UI: MM/DD/YYYY.
 *
 * This function accepts a `date` argument of any of the following types:
 * - `Date` object
 * - `Dayjs` object with or without timezone
 * - string in format '2023-10-05T09:59:59.999Z'
 * - string in format YYYY-MM-DD
 * - string in format MM/DD/YYYY (in this case, return the string as-is)
 *
 * If `tz` is not specified, this function will use the timezone set via dayjs.tz.setDefault(), or
 * fall back to the browser's timezone.
 *
 * Background: timezones matters when displaying dates:
 * - Dates are stored as UTC in the DB
 * - dayjs(date) returns the date in the *current browser's* timezone. This might give an incorrect date because
 *   the day of the week might be different in the browser timezone and in the org timezone.
 * - dayjs.tz(date, org.tz) returns the correct date for specified timezone, indenpendently of browser.
 */
export const formatDate = (date: Dayjs | Date | string | null, tz?: string) => {
  if (date == null) {
    return '';
  }
  const displayFormat = 'MM/DD/YYYY';
  if (typeof date == 'string') {
    if (date.length == localDateFormat.length) {
      let parsed: Dayjs;
      // Try YYYY-MM-DD
      parsed = dayjs(date, localDateFormat, true);
      if (parsed.isValid()) {
        return parsed.format(displayFormat);
      }
      // Try MM/DD/YYYY
      parsed = dayjs(date, displayFormat, true);
      if (parsed.isValid()) {
        return date;
      }
      throw new Error(`Invalid date format: ${date}`);
    } else {
      // Convert to date because of the following behavior:
      //   > dayjs.tz.setDefault('America/Chicago')
      //   > const x = '2023-10-05T04:59:59.999Z'
      //   > dayjs.tz(x).format('MM/DD/YYYY')
      //   '10/05/2023'
      //   > dayjs.tz(new Date(x)).format('MM/DD/YYYY')
      //   '10/04/2023'
      date = new Date(date);
    }
  }
  return tz ? dayjs.tz(date, tz).format(displayFormat) : dayjs.tz(date).format(displayFormat);
};

export const formatTime = (dateTime: string | null | undefined, tz: string) => {
  if (!dateTime) {
    return '';
  }
  const displayFormat = 'h:mm a';
  let parsedDate = dayjs.utc(dateTime);
  if (tz) {
    parsedDate = parsedDate.tz(tz);
  }
  return parsedDate.format(displayFormat);
};

export const formatRelativeDate = (localdate: string) => {
  if (!localdate) {
    return '';
  }
  const dateJs = dayjs.tz(localdate);
  if (dateJs.isSame(dayjs.tz(), 'day')) {
    return 'Today';
  }
  if (dateJs.isSame(dayjs.tz().subtract(1, 'day'), 'day')) {
    return 'Yesterday';
  }
  return dateJs.format('MM/DD');
};

export const getUserInitials = (name: string | null | undefined) => {
  if (!name) return 'X';

  // replace multiple spaces with one and split on space
  const parts = name.replace(/ +(?= )/g, '').split(' ');

  return parts.length == 1 ? `${parts[0][0] ?? 'X'}`.toUpperCase() : `${parts[0][0]}${parts[1][0]}`.toUpperCase();
};
